<template>
  <SiteHeader
    @hash="$router.push({
      path: '/',
      hash: $event
    })"
    @login="showLogin"
  />
  <div class="site">
    <div class="site__wrapper">
      <slot />
      <div class="site__cta">
        <SiteButton
          type="secondary"
          label="⚡️ Générer un CRA Express"
          has-arrow
          class="site-pitch__wrapper__text__action__button"
          full-width-mobile
          :href="$router.resolve('/cra-express').fullPath"
          @click.prevent="$router.push('/cra-express')"       
        />
      </div>
    </div>
  </div>
  <SiteFooter
    @hash="$router.push({
      path: '/',
      hash: $event
    })"
  />
  <SitePostFooter />

  <SiteSignUp
    @login="showLogin"
    @authenticated="goToApp"
    allow-desauthent
    v-model="signUpVisible"
  />
  <SiteLogin
    @signup="showSignUp('login')"
    allow-desauthent
    @authenticated="goToApp"
    v-model="loginVisible"
  />
  <GlobalEvents
    target="window"
    @scroll="checkHashTag"
  />
  <SiteOverlay />
</template>

<script setup>
  import SiteHeader from '@/sections/SiteHeader'
  import SiteFooter from '@/sections/SiteFooter'
  import SitePostFooter from '@/sections/SitePostFooter'
  import SiteSignUp from '@/components/SiteSignUp'
  import SiteLogin from '@/components/SiteLogin'
  import SiteOverlay from '@/components/SiteOverlay'
  import { GlobalEvents } from 'vue-global-events'

  const goToApp = () => {
    location.href = config.public.appUrl
  }

  const loginVisible = ref(false)

  const showLogin = ()=> {
    loginVisible.value = true
  }

  onMounted(() => {
    useHead({
      link: [{
        href: 'https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&display=swap',
        rel: 'stylesheet'
      }]
    })
  })
</script>

<style lang="scss">
  @use '@/sections/SitePreHeader/index.scss' as preHeader;

  @use '@/components/SiteButton/index.scss' as button;
  @use '@/components/SiteLogoTitle/index.scss' as logoTitle;
  @use '@/sections/SiteHeader/SiteBurger/index.scss' as headerBurger;
  @use '@/sections/SiteHeader/SiteMenu/index.scss' as headerMenu;
  @use '@/sections/SiteHeader/SiteMenuButton/index.scss' as headerMenuButton;
  @use '@/sections/SiteHeader/SiteMobileMenu/index.scss' as headerMobileMenu;
  @use '@/sections/SiteHeader/index.scss' as header;
  @use '@/sections/home/SiteHeroBanner/index.scss' as heraBanner;
  @use '@/sections/home/SitePitch/index.scss' as pitch;

  $desktop-breakpoint: 990px;
  $tablet-breakpoint: 672px;

  html, body {
    margin: 0;
    padding: 0;
    font-family: 'Outfit', sans-serif;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    &.blocked {

      #__nuxt {
        overflow: hidden;
        height: 100vh;
        position: relative;
      }
    }

    .site-section-title {
      font-size: 40px;
      font-weight: 500;

      @media screen and (min-width: $desktop-breakpoint) {
        font-size: 60px;
        font-weight: 600;
      }
    }
  }

  $desktop-breakpoint: 990px;
  $tablet-breakpoint: 672px;

  .site {
    padding: 40px 15px 15px;
   
    a {
      text-decoration: none;
      color: rgb(1, 73, 139);
    }

    @media screen and (min-width: $tablet-breakpoint) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media screen and (min-width: $desktop-breakpoint) {
      display: flex;
      justify-content: center;
    }

    &__wrapper {
      max-width: 1300px;
    }

    &__cta {
      display: flex;
      justify-content: center;
      margin: 35px;
    }
  }
</style>